/* eslint-disable */
/*NOT USE*/
import {TextColumnConfig, ITextColumnConfig} from './textColumnConfig';

export class DescriptionColumnConfig extends TextColumnConfig {
  constructor(config: IDescriptionColumnConfig) {
    super(config);
  }
}
export interface IDescriptionColumnConfig extends ITextColumnConfig {}
